import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useQuery } from "@tanstack/react-query";
import { getReserveQuery } from "../../../queries";
import classnames from "classnames";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import AccordionReserve from "../ui/accordion-reserve";

const values = {
  page: 1,
  size: 20,
  desc: true,
};

const schema = createSchema({});

const Reserve = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getReserveQuery({ ...watch() }),
  });

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("orders")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === "" },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", "")}
                        href="#"
                      >
          
                        {t("all_reserve")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 0 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 0)}
                        href="#"
                      >
                
                        {t("reserve_created")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 1 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 1)}
                        href="#"
                      >
                    
                        {t("reserve_undrway")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 2 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 2)}
                        href="#"
                      >
                     
                        {t("reserve_sent")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 3 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 3)}
                        href="#"
                      >
                 
                        {t("reserve_complated")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 4 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 4)}
                        href="#"
                      >
                   
                        {t("reserve_cancelled")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 5 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 5)}
                        href="#"
                      >
            
                        {t("reserve_refund")}
                      </NavLink>
                    </NavItem>
                  </Nav>


                  {isFetched ? (
                    <>
                      <AccordionReserve
                        data={data?.items}
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                      />

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reserve;
